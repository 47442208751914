// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-angebote-js": () => import("./../../../src/pages/angebote.js" /* webpackChunkName: "component---src-pages-angebote-js" */),
  "component---src-pages-gesuche-js": () => import("./../../../src/pages/gesuche.js" /* webpackChunkName: "component---src-pages-gesuche-js" */),
  "component---src-pages-immobilien-suche-js": () => import("./../../../src/pages/immobilien-suche.js" /* webpackChunkName: "component---src-pages-immobilien-suche-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-form-js": () => import("./../../../src/pages/kontakt-form.js" /* webpackChunkName: "component---src-pages-kontakt-form-js" */),
  "component---src-templates-offer-js": () => import("./../../../src/templates/Offer.js" /* webpackChunkName: "component---src-templates-offer-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

