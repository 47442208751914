/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
require("./src/css/bootstrap.min.css")
require ("./src/css/layout.css")
require( "./src/css/main.scss")

// in gastby-browser.js
exports.onInitialClientRender = () => {
    window.scrollTo(0, 0);
  };